<template>
  <div class="mainContainer">
    <Navbar class="nav" navTitle=" "></Navbar>

    <main class="main">
      <van-nav-bar title="地址监控" left-text="返回" right-text="新增地址" @click-left="$router.go(-1)"
        @click-right="addModal = true" />
      <div class="balanceContainer">
        <van-cell v-for="(item, index) in currentPageData" @click="openChart(index)" :title="index + 1 + '.' + item.name"
          :key="item._id" is-link>
          <div style="font-size: 12px; display: flex; justify-content: flex-end;" slot="default">
            <span class="deeper_china"
              style="font-size: 12px;display: inline-block;  white-space: normal; word-break: break-word; margin-right: 10px;">
              {{ item.deeperChain }}
            </span>
            <div>
              <span v-if="balanceObj[item.deeperChain]">{{ getDPR(balanceObj[item.deeperChain].now.balance) }} DPR</span>
              -
              <span v-if="creditObj[item.deeperChain]">{{ creditObj[item.deeperChain].now.credit }} 信用值</span>
              <span v-else>暂无信用值</span>
            </div>
          </div>
        </van-cell>
      </div>
    </main>

    <van-popup round style="width: 100%; max-width: 600px" v-model="addModal">
      <div style="padding: 30px">
        <van-form @submit="addSubmit">
          <van-field v-model="addForm.deeperChain" name="钱包地址" label="钱包地址" placeholder="请输入DeeperChain钱包地址"
            :rules="[{ required: true, message: '请输入DeeperChain钱包地址' }]" />
          <van-field v-model="addForm.name" name="备注名" label="备注名" placeholder="请输入备注名"
            :rules="[{ required: true, message: '请输入备注名' }]" />
          <div style="margin: 16px">
            <van-button round block type="info" native-type="submit">新增</van-button>
          </div>
        </van-form>
      </div>
    </van-popup>

    <van-popup style="background: transparent" position="bottom" v-model="chartModal">
      <div style="max-width: 600px; width: 100%; margin: 0 auto; background: #fff">
        <van-nav-bar :title="chartDetail.name" left-text="上一个" right-text="下一个" left-arrow right-arrow
          @click-left="openChart(chartDetailIndex - 1)" @click-right="openChart(chartDetailIndex + 1)" />
        <van-cell v-if="creditObj[chartDetail.deeperChain]" title="是否质押成功" :value="creditObj[chartDetail.deeperChain].now.delegating ? '是' : '否'
          "></van-cell>
        <van-cell v-if="creditObj[chartDetail.deeperChain]" title="剩余奖励次数"
          :value="creditObj[chartDetail.deeperChain].now.countDown || '未激活'"></van-cell>
        <van-cell v-if="creditObj[chartDetail.deeperChain]" title="当日是否激活" :value="creditObj[chartDetail.deeperChain].now.imOnlineTime / 1 >
          creditObj[chartDetail.deeperChain].now.eraStartTime / 1
          ? '是'
          : '否'
          "></van-cell>
        <div style="font-size: 12px; text-align: center; padding: 20px 0">
          {{ chartDetail.deeperChain }}
        </div>
        <div class="chartBox" id="balanceChart"></div>
        <div class="chartBox" id="creditChart"></div>
        <div style="padding: 10px">
          <div>
            <van-button block plain @click="deleteDeeperChain(chartDetailIndex)" type="danger">停止监控</van-button>
          </div>
        </div>
      </div>
    </van-popup>

    <van-pagination v-if="pageCount > 1" v-model="currentPage" :total-items="totalCount" :items-per-page="itemsPerPage"
      @change="handleChangePage" />

    <Footer class="footer"></Footer>
  </div>
</template>

<script>
import Vue from "vue";
import Footer from "@/components/Footer/Footer";
const validateDeeperChain = require("@/tools/validateDeeperChain");
import * as echarts from "echarts";
import moment from "moment";

export default {
  components: {
    Footer,
  },
  data() {
    return {
      activeNames: [],
      deeperChainList: [],
      addModal: false,
      editModal: false,
      chartModal: false,
      addForm: {
        deeperChain: "",
        name: "",
      },
      balanceObj: {},
      creditObj: {},
      chartDetail: {},
      chartDetailIndex: 0,
      balanceChart: null,
      creditChart: null,
      currentPageData: [],
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  mounted() {
    this.getList();
  },
  watch: {
    deeperChainList() {
      this.handleChangePage(this.currentPage);
    },
    currentPage(newValue) {
      this.handleChangePage(newValue);
    }
  },
  computed: {
    pageCount() {
      return Math.ceil(this.deeperChainList.length / this.itemsPerPage);
    },
    totalCount() {
      return this.deeperChainList.length;
    },
  },
  methods: {
    deleteDeeperChain(index) {
      let item = this.deeperChainList[index];
      this.$Dialog
        .confirm({
          title: "停止监控",
          message: `确认要停止监控${item.name}吗？`,
        })
        .then(() => {
          this.$fetch({
            url: "/watchBalance/deeperChain",
            headers: {
              Authorization: `${window.localStorage.getItem("token")}`,
            },
            method: "DELETE",
            data: {
              id: item._id,
            },
          }).then((res) => {
            if (res.success) {
              this.$toast.success();
              this.chartModal = false;
              this.getList();
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    async openChart(index) {
      if (index < 0) {
        index = this.deeperChainList.length - 1;
      }
      if (index > this.deeperChainList.length - 1) {
        index = 0;
      }
      if (!this.balanceObj[this.chartDetail.deeperChain]) {
        this.$toast.loading({
          duration: 0,
        });
        await this.getBalance([this.deeperChainList[index].deeperChain]);
        this.$toast.clear();
      }
      if (!this.creditObj[this.chartDetail.deeperChain]) {
        this.$toast.loading({
          duration: 0,
        });
        await this.getCredit([this.deeperChainList[index].deeperChain]);
        this.$toast.clear();
      }

      this.chartDetailIndex = index;
      this.chartDetail = this.deeperChainList[index];
      this.chartModal = true;
      let balanceItem = this.balanceObj[this.chartDetail.deeperChain] || {};
      let creditItem = this.creditObj[this.chartDetail.deeperChain] || {};
      this.$nextTick(() => {
        if (!this.balanceChart) {
          this.balanceChart = echarts.init(
            document.getElementById("balanceChart")
          );
        }
        if (!this.creditChart) {
          this.creditChart = echarts.init(
            document.getElementById("creditChart")
          );
        }
        if (!balanceItem.history) {
          balanceItem.history = [];
        }
        this.balanceChart.setOption({
          title: {
            text: "余额",
            left: "center",
          },
          xAxis: {
            data: balanceItem.history.map((it) =>
              moment(it.createdAt).format("MM-DD HH")
            ),
          },
          yAxis: {
            min: "dataMin",
            max: "dataMax",
          },
          series: [
            {
              name: "余额",
              type: "line",
              data: balanceItem.history.map((it) => this.getDPR(it.balance)),
            },
          ],
        });
        if (!creditItem.history) {
          creditItem.history = [];
        }
        this.creditChart.setOption({
          title: {
            text: "信用值",
            left: "center",
          },
          xAxis: {
            data: creditItem.history.map((it) =>
              moment(it.createdAt).format("MM-DD HH")
            ),
          },
          yAxis: {
            min: "dataMin",
            max: "dataMax",
          },
          series: [
            {
              name: "信用值",
              type: "line",
              data: creditItem.history.map((it) => it.credit),
            },
          ],
        });
      });
    },
    getDPR(balance) {
      return (balance / 1e18).toFixed(2) / 1;
    },
    async getBalance(list) {
      for (let deeperChain of list) {
        await this.$fetch({
          url: "/watchBalance/balance",
          headers: {
            Authorization: `${window.localStorage.getItem("token")}`,
          },
          params: {
            deeperChain,
          },
        }).then((res) => {
          if (res.success) {
            Vue.set(this.balanceObj, res.data.now._id, res.data);
          }
        });
      }
    },
    async getCredit(list) {
      for (let deeperChain of list) {
        await this.$fetch({
          url: "/watchBalance/credit",
          headers: {
            Authorization: `${window.localStorage.getItem("token")}`,
          },
          params: {
            deeperChain,
          },
        }).then((res) => {
          if (res.success && res.data) {
            Vue.set(this.creditObj, res.data.now._id, res.data);
          }
        });
      }
    },
    getList() {
      this.$fetch({
        url: "/watchBalance/deeperChainList",
        headers: {
          Authorization: `${window.localStorage.getItem("token")}`,
        },
      }).then((res) => {
        console.log("325", res);
        this.deeperChainList = res.data;
        this.getBalance(this.deeperChainList.map((it) => it.deeperChain));
        this.getCredit(this.deeperChainList.map((it) => it.deeperChain));
        if (!this.deeperChainList.length) {
          this.addModal = true;
        } else {
          this.handleChangePage(this.currentPage);
        }
      });
    },
    addSubmit() {
      if (!validateDeeperChain(this.addForm.deeperChain)) {
        return this.$toast("请输入正确的DeeperChain地址");
      }
      this.$fetch({
        url: "/watchBalance/deeperChain",
        headers: {
          Authorization: `${window.localStorage.getItem("token")}`,
        },
        method: "POST",
        data: {
          deeperChain: this.addForm.deeperChain,
          name: this.addForm.name,
        },
      }).then((res) => {
        if (res.success) {
          this.$toast.success();
          this.addModal = false;
          this.addForm = {
            deeperChain: "",
            name: "",
          };
          this.getList();
        }
        console.log(res);
      });
    },
    handleChangePage(currentPage) {
      this.currentPage = currentPage;
      const startIndex = (currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      this.currentPageData = this.deeperChainList.slice(startIndex, endIndex);
    }
  },
};
</script>
<style>
html,
body,
#app {
  height: 100%;
}
</style>
<style lang="less" scoped>
// on Phone 
@media screen and (max-width: 768px) {
  .nav {
    position: fixed;
    top: 0;
    width: 100vw;
  }

  .footer {
    position: fixed;
    bottom: 30px;
    width: 100vw;
  }

  .main {
    padding-top: 60px;
    background: #fff;
  }

  .balanceContainer {
    box-sizing: border-box;
    height: 60vh;
    overflow-y: auto;

    .van-cell__title {
      position: absolute;
      width: 50px;

      span {
        width: 50px;
      }
    }
  }

  .mainContainer {
    height: auto;

    .deeper_china {
      width: 172px;
    }

    .van-pagination__item--disabled {
      opacity: 1 !important;
    }

  }
}

// on Computer
@media screen and (min-width: 769px) {
  .main {
    max-width: 700px;
    margin: 20px auto 0;
    padding-top: 60px;
    padding-bottom: 100px;
    background: #fff;
  }

  .balanceContainer {
    box-sizing: border-box;
    height: 50vh;

    .van-cell__title {
      position: absolute;
      width: 70px;

      span {
        width: 70px;
      }
    }
  }

  .mainContainer {
    overflow-y: hidden;

    .deeper_china {
      width: 400px;
    }

    .van-pagination {
      max-width: 700px;
      margin: auto;
    }
  }
}

.nav {
  position: fixed;
  top: 0;
  width: 100vw;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
}

.mainContainer {
  min-height: 100%;
  background: #3f3f3f;
}

.balanceContainer .van-cell__title {
  position: absolute;
  width: 50px;

  span {
    display: inline-block;
    height: 48px;
    white-space: normal;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    line-clamp: 2;
  }
}

.chartBox {
  height: 200px;
}
</style>
